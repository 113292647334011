.blog-container {
  max-width: 700px;
}

div.blog-content {
  // Headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1em 0;
  }
  h2 {
    @apply text-3xl;
  }

  h2 {
    @apply text-5xl;
  }

  h3 {
    @apply text-2xl;
  }

  h4 {
    @apply text-xl;
  }

  p {
    @apply text-lg;
    @apply py-3;
    font-weight: 300;
  }

  ul {
    @apply list-disc;
    @apply ml-8;
    @apply text-lg;
    @apply leading-8;
  }

  li {
    @apply mb-2;
    font-weight: 300;
  }

  figure {
    margin: 2em 0;
  }

  figure > img {
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: 0.5em;
  }

  figure > figcaption,
  figure > figcaption > a {
    color: #696969;
    font-size: 12px;
  }

  blockquote > p {
    font-weight: 700;
    color: #6c84fa;
    margin-top: 1em;
  }

  blockquote > cite {
    font-style: normal;
    font-weight: 700;
    font-size: 0.875em;
    color: #696969;
    margin-top: 1em;
  }
  a {
    font-weight: 700;
    text-decoration: underline;
  }

  .wp-block-separator {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  table {
    @apply min-w-full divide-y divide-gray-300;
  }

  tbody {
    @apply divide-y divide-gray-200 bg-white;
  }

  td {
    @apply px-3 py-4 text-sm;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "_content";

html {
  scroll-behavior: smooth;
}

html,
body {
  @apply font-body;
  // overflow-x: hidden;
  scroll-behavior: smooth;
}

.header-container {
  max-width: 1440px;
}

.section-container {
  max-width: 1440px;
}

.content-container {
  max-width: 1140px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  @apply font-display font-bold;
}

h1,
.h1 {
  @apply text-6xl leading-normal;
  font-size: clamp(2.25rem, 1.24rem + 4.31vw, 4rem);
}

/* A NOTE ON CLAMP:
  Heading font sizes have been calculated to scale between the minimum and maximum sizes (determined according to prototype designs),
  where the upper viewport limit is 1024px, and the lower limit is 375px. 
  The preferred value is calculated as follows (values must be in rem):
  slope = (maxFontSize - minFontSize) / (maxWidth - minWidth)
  yAxisIntersection = -minWidth * slope + minFontSize
  preferredValue = yAxisIntersection[rem] + (slope * 100)[vw]
  SOURCE: https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
  */

h2,
.h2 {
  @apply leading-normal;
  font-size: clamp(2.25rem, 1.82rem + 1.85vw, 3rem);
}

h3,
.h3 {
  @apply leading-normal;
  font-size: clamp(1.25rem, 0.674rem + 2.46vw, 2.25rem);
}

h4,
.h4 {
  @apply text-4xl;
}

h5,
.h5 {
  @apply text-2xl;
}

h6,
.h6 {
  @apply text-xl;
}
